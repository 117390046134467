const ungroupedMenuItem = {
  "label": "Dashboard",
  "link": "/",
  "icon": "ri-dashboard-line",
  allowedUserTypes: [1, 2]
};


const collegeResources = {
  label: "College Resources",
  allowedUserTypes: [1,2],
  items:[

    {
      "label": "Major Matchmaker",
      "link": "/major-matchmaker",
      "icon": "ri-key-2-line",
      "allowedUserTypes": [1,2]
    },
    {
      "label": "College List",
      "link": "/list",
      "icon": "ri-bookmark-3-line",
      "allowedUserTypes": [1, 2]
    },


    {
      "label": "College Comparison (Beta)",
      "link": "/college-comparison",
      "icon": "ri-filter-line",
      "allowedUserTypes": [1, 2]
    },

    {
      "label": "College Discovery",
      "link": "/discovery",
      "icon": "ri-bank-line",
      "allowedUserTypes": [1, 2]
    },
    {
      "label": "Scholarship Finder",
      "link": "/scholarships",
      "icon": "ri-trophy-line",
      "allowedUserTypes": [1, 2]
    },
  ]
};

const applicationManagement = {
  label: "Application Management",
  allowedUserTypes: [1,2],
  items: [


    {
      "label": "Application Tracker",
      "link": "/applications",
      "icon": "ri-bar-chart-grouped-line",
      "allowedUserTypes": [1, 2]
    },

    {
      "label": "Deadlines Management",
      "link": "/deadlines",
      "icon": "ri-calendar-line",
      "allowedUserTypes": [1, 2]
    },
    {
      "label": "Course",
      "link": "/course",
      "icon": "ri-book-open-line",
      "allowedUserTypes": [1, 2]
    },
  ]
};

const aiAssistance = {
  label: "AI Assistance",
  allowedUserTypes:[1,2],
  items: [
    {
      "label": "Application Analyzer",
      "link": "/analyze",
      "icon": "ri-bubble-chart-line",
      "allowedUserTypes": [1, 2]
    },
    {
      "label": "Essay Review",
      "link": "/essay-review",
      "icon": "ri-edit-2-line",
      "allowedUserTypes": [1, 2]
    },
    {
      "label": "College Consulting",
      "link": "/chat",
      "icon": "ri-ghost-line",
      "allowedUserTypes": [1, 2]
    },
  ]
};


const socialFeatures = {
  label: "Social Features",
  allowedUserTypes: [1,2],
  items:[
    {
      "label": "Support",
      "link": "/support",
      "icon": "ri-thumb-up-line",
      "allowedUserTypes": [1, 2]
    },
  ]
};

const adminFeatures = {
  label: "Admin Tools",
  allowedUserTypes: [100],
  items:[
    {
      "label": "Plan Management",
      "link": "/plan-management",
      "icon": "ri-money-dollar-circle-line",
      "allowedUserTypes": [100]
    },
    {
      "label": "Support System Management",
      "link": "/support-management",
      "icon": "ri-user-voice-line",
      "allowedUserTypes": [100]
    },
    {
      "label": "Analytics",
      "link": "/analytics",
      "icon": "ri-bar-chart-grouped-line",
      "allowedUserTypes": [100]
    },
  ]
};

const supportFeatures = {
  label: "Customer Support Tools",
  allowedUserTypes: [80],
  items:[
    {
      "label": "Course Management",
      "link": "/sections",
      "icon": "ri-book-open-line",
      "allowedUserTypes": [70,80,90,100]
    },
    {
      "label": "Ticketing Management",
      "link": "/supp-dashboard",
      "icon": "ri-thumb-up-line",
      "allowedUserTypes": [70,80,90,100]
    },
    {
      "label": "Tip Management",
      "link": "/tip-management",
      "icon": "ri-lightbulb-line",
      "allowedUserTypes": [70,80,90,100]
    },
    {
      "label": "Questions Management",
      "link": "/questions-management",
      "icon": "ri-question-line",
      "allowedUserTypes": [80,90,100]
    },
  ]
};

export { ungroupedMenuItem, collegeResources, applicationManagement, aiAssistance, socialFeatures, adminFeatures, supportFeatures };